/* Services (CMS API calls) */
import { fetchNavigation } from '@services/navigation';
import { fetchFooter } from '@services/footer';
import { fetchSlug } from '@services/slug';

/* Components */
import { NextSeo } from 'next-seo';
import PageLayout from '@components/core/layout/PageLayout';
import Navigation from '@components/Navigation';
import Footer from '@components/Footer';
import { HeaderSideImage } from '@components/headers';
import SectionBox from '@components/SectionBox';
import Image from 'next/image';

const styling = {
  decorativeBarImage: sq`
    absolute
    top-0
    left-0
    bottom-0
    right-0
    rounded-tl-[1rem]
    rounded-tr-[1rem]

    md:rounded-tl-[1.5rem]
    md:rounded-tr-[1.5rem]

    2xl:rounded-tl-[2rem]
    2xl:rounded-tr-[2rem]
  `,
  decorativeBarOverlay: sq`
    absolute
    bottom-0
    w-full
    h-[calc(100%-0.25rem)]
    rounded-tl-[1rem]
    rounded-tr-[1rem]

    md:h-[calc(100%-0.5rem)]
    md:rounded-tl-[1.5rem]
    md:rounded-tr-[1.5rem]

    2xl:rounded-tl-[2rem]
    2xl:rounded-tr-[2rem]
  `,
};

export async function getStaticProps({ locale }) {
  const [
    navigationQueryResult,
    footerQueryResult,
    pageProps,
  ] = await Promise.all([
    fetchNavigation(locale, false),
    fetchFooter(locale, false),
    fetchSlug('404', locale, false),
  ]);

  return {
    props: {
      pageProps,
      locale,
      navigationQueryResult,
      footerQueryResult,
    },
  };
}

const staticProps = {
  heading: 'Oeps!\nDeze pagina bestaat niet',
  intro: 'Ga terug naar de homepagina of bekijk de veelgestelde vragen.',
  ctaButton1: {
    label: 'Naar de homepagina',
    link: {
      internalLink: {
        slug: {
          current: '',
        },
      },
    },
  },
  ctaButton2: {
    label: 'Veelgestelde vragen',
    link: {
      internalLink: {
        slug: {
          current: 'veelgestelde-vragen',
        },
      },
    },
  },
  staticSrc: '/assets/404.png',
  'alt': '404: Deze pagina kon niet worden gevonden',
};

export default function Custom404({ navigationQueryResult, footerQueryResult, pageProps, locale }) {
  // First item has background?
  let backgroundColorHeader = 'bg-white';
  if (pageProps[0]?.sectionBox[0]) {
    if (pageProps[0]?.sectionBox[0]._type === 'component') {
      backgroundColorHeader = pageProps[0].sectionBox[0].sections[0]?.sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    } else {
      backgroundColorHeader = pageProps[0].sectionBox[0].sectionBackgroundColor ? 'bg-primary-50' : 'bg-white';
    }
  }

  const hasSections = pageProps && pageProps.length > 0 && pageProps[0].sectionBox && pageProps[0].sectionBox.length > 0;

  return (
    <>
      <NextSeo
        title={ '404: Deze pagina kon niet worden gevonden' }
        noindex={ true }
        nofollow={ true }
      />
      <PageLayout theme={ 'kinderopvang' }>
        {
          navigationQueryResult &&
          <Navigation { ...navigationQueryResult } theme={ 'kinderopvang' } />
        }

        <header className={
          sq`
            bg-primary-50
            -mb-6
            pb-6
            md:-mb-10
            md:pb-10
          `
        }>
          <HeaderSideImage { ...staticProps } />
        </header>
        {
          hasSections &&
          <div className='relative h-6 md:h-10 -mb-6 md:-mb-10'>
            <div className='absolute top-0 bottom-0 left-0 right-0'></div>
            <Image
              src={ '/assets/domain-bars/kinderopvang.svg' }
              className={ styling.decorativeBarImage }
              alt='Decorative bar'
              fill={ true }
              sizes='(min-width: 1280px) 1440px, (min-width: 768px) 750px, 640px'
              unoptimized={ true }
            />
            <div className={ `${ styling.decorativeBarOverlay } ${ backgroundColorHeader }` } />
          </div>
        }
        {
          hasSections &&
            <SectionBox
              sections={ pageProps[0].sectionBox }
              locale={ locale }
            />
        }
        {
          footerQueryResult &&
          <Footer { ...footerQueryResult } theme={ 'kinderopvang' }  />
        }
      </PageLayout>
    </>
  );
}
